<template>
  <div class="wrapper">
    <!-- 帮助中心-->
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="标题">
          <el-input placeholder="请输入" v-model="searchData.title" size="small"></el-input>
        </el-form-item>
        <el-form-item label="分类类型">
          <SelectDict v-model="searchData.type" dictCode="system_help_center_type"></SelectDict>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <div class="header-btn">
      <el-button
        v-checkbtn="isShowBtn(AUTH_BTN.help_list_add)"
        @click="$router.push({ name: 'HelpCenterAdd' })"
        type="primary"
        icon="el-icon-plus"
        size="small"
        >新增</el-button
      >
    </div>
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
  </div>
</template>

<script>
import { getListAPI, delAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
import SelectDict from '@/views/components/select-dict.vue'
const columns = [
  {
    label: '分类类型',
    prop: 'type_name',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['type_name']}</p>
    }
  },

  {
    label: '标题',
    prop: 'title',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['title']}</p>
    }
  },
  {
    label: '内容',
    prop: 'content',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['content']}</p>
    }
  },
  {
    label: '状态',
    prop: 'status',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['status'] ? '展示' : '不展示'}</p>
    }
  },
  {
    label: '排序',
    prop: 'sort',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['sort']}</p>
    }
  },

  {
    label: '操作',
    width: '150',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.help_list_edit)}
            underline={false}
            type="primary"
            onClick={() => this.$router.push({ name: 'HelpCenterEdit', params: { id: row['id'] } })}
          >
            编辑
          </el-link>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.help_list_del)}
            style="margin-left:10px"
            underline={false}
            type="primary"
            onClick={() => this.handleRemove(row)}
          >
            删除
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  components: { SelectDict },
  data() {
    return {
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        title: '',
        type: ''
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.getList()
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  methods: {
    async getList() {
      const { title, type } = this.searchData
      let params = { page: this.currentPage, page_size: this.pageSize, title, type }
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    handleRemove({ id }) {
      this.$confirm('此操作将永久删除金额, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 状态
    changeStatus(row) {
      let { id, status } = row
      let data = { id, status: status == 0 ? 0 : 1 }
      let statusDesc = status == 0 ? '下架' : '上架'

      this.$confirm(`此操作将该商品${statusDesc}吗, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          row.status = status == 0 ? 0 : 1
          changeStatusAPI(data).then(() => {
            this.getGoodsList()
            this.$message.success('操作成功')
          })
        })
        .catch(() => {
          row.status = status == 1 ? 0 : 1
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .header-search {
    .el-form {
      .el-form-item {
        margin-right: 35px;
        .el-input {
          width: 250px;
        }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 35%;
          }
        }
      }
      // .el-select .el-input {
      //   width: 100px;
      // }
      // .input-with-select {
      //   vertical-align: middle;
      // }
      // .input-with-select .el-input-group__prepend {
      //   background-color: #fff;
      // }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 10px;
  }
  .header-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .tp-table {
    .el-table__fixed-right {
      height: 100% !important;
    }
  }
  .el-dialog__body {
    padding-top: 0;
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
  }
  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }
  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
